import React,{useEffect,useState} from 'react'
import { useParams,createSearchParams } from 'react-router-dom'
import axios from 'axios';
import {Grid,Typography,Box,Rating} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style.css'
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dialog from '@mui/material/Dialog';
import { ADDRGETNETWORKPARAMS } from 'dns';
import datanotfound from '../../assets/datanotfound.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? '#f0f3f7' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#e24c4c',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(226,76,76,0.91)',
    zIndex: 9999,
  },
}));
const SecondPage = () => {
  const {value} = useParams();
  const [results,setResults]=useState([]);
  const [categorylist,setCategorylist]=useState([]);
  const [threesearchData,setThreesearchData]=useState([]);
  const [categoryapi,setCategoryapi]=useState([]);
  const [active,setActive]=useState<any>(null);
  const [networklist,setNetworklist] = useState<any>([])
  const [networkDatas,setNetworkDatas] = useState<any>([])
  const [notfound,setNotfound] = useState<any>(null)
  const queryparams = new URLSearchParams(window.location.search);
  const name = queryparams.get('category');
  const networkName = queryparams.get('protocol');

  const [networkState,setNetworkState] = useState<any>(null);
  
  let test: Array<string> = [];

  useEffect( ()=>{
    async function initial(){
      setTimeout(() => {
        setNotfound('data');
      }, 3000);
      const { data  } = await axios.get('https://dappstore.mo.vc/api/network-list')
      setNetworklist(data);
     }
    initial()
   },[])
  
  useEffect( ()=>{
    async function initial(){
      const { data   } = await axios.get('https://dappstore.mo.vc/api/category-list')
      setCategorylist(data);
     }
    initial()
   },[])
   useEffect( ()=>{
    async function initial(){
      const { data   } = await axios.get(`https://dappstore.mo.vc/api/category-dapp-lists?search=${value}&&protocol=${networkName}`)
      setNetworkDatas(data);
     }
     setNetworkDatas([])
    initial()
   },[networkName])
   useEffect( ()=>{
    async function initial(){
      const { data   } = await axios.get(`https://dappstore.mo.vc/api/category-dapp-lists?search=${value}&&category=${name}&&protocol=${networkName}`)
      setThreesearchData(data);
     }
     setThreesearchData([]); 
    initial()
   },[name,networkName])

   useEffect( ()=>{
    async function initial(){
      try {
        const { data } = await axios.get(`https://dappstore.mo.vc/api/category-dapp-lists?search=${value}`);
        setResults(data);
        setActive(null);
      } catch (error) {
        setResults([])
        setActive(null);
      }
     }
    initial()
   },[value])

   useEffect(() => {
    async function getCategoryData() {
      try {
        const { data } = await axios.get(`https://dappstore.mo.vc/api/category-dapp-lists?search=${value}&&category=${name}`);
        setCategoryapi(data);
      } catch (error) {
        console.error(error);
      }
    }
    
    setCategoryapi([]); 

    getCategoryData();
  }, [name]);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate=useNavigate();
  const handleRowFunction = (id:any) => {
    navigate('/grid/'+id)
  }
  function Chipcolor() {
    return <Typography className='open-name' style={{
      fontSize: '12px',
      cursor: 'pointer',
      color: '#b33030',
      height: '18px',
      fontWeight:'500px',
      fontFamily: 'Poppins'
    }}>GET</Typography>
  }
  const handleFunction = (name:any) => {
  
    const searchParams = new URLSearchParams();

    {networkState && 
      searchParams.append('protocol', networkState);
    }
    searchParams.append('category', name);
    
    const searchQuery = searchParams.toString();
    navigate(`/search/${value}?${searchQuery}`);

  

  }
  

  
/* protocol */
const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const open = Boolean(anchorEl);
const handleClickprotocol = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
const categoryFunc = () =>{
  navigate(`/search/${value}`);
  setActive("categories");
}

const [value1,setValue1]= useState<any>(0);
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue1(newValue);
};


const handleNetwork = (networkName:any) => {
  setNetworkState(networkName);
  const params = new URLSearchParams();
  params.set('protocol', networkName);
  const search = params.toString();
  navigate(`/search/${value}?${search}`);
 
 
}


console.log(value,name,networkName);
console.log('Results',results);
console.log('Category',categoryapi);
console.log('network',networkDatas);
console.log('3search',threesearchData);



  return (
    <Box sx={{padding:'10px'}}>
     <h1 className="back-icon" onClick={()=>navigate('/')}><ChevronLeftIcon/>Back</h1>
    <div className='protocol'>

    <Button
  id="demo-customized-button"
  aria-controls={open ? 'demo-customized-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  variant="contained"
  disableElevation
  onClick={handleClickprotocol}
  endIcon={<KeyboardArrowDownIcon />}
  className="protocol-btn"
>
{networkName ? networkName : 'All Protocols'}
</Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='styled_menu'
      >
       {networklist?.map((item:any,index:any)=>(
  <MenuItem onClick={()=>{handleNetwork(item.network);handleClose()}} disableRipple key={index} className='network_dropdown'>
<img src={item?.logo} width={18}></img>
  <p className='network_name'>{item?.network}</p>

</MenuItem>
  ))}
      
      </StyledMenu>
    </div>
     <div className='category_list'>
      
    
     <Box
      sx={{
        flexGrow: 1,
        maxWidth: {xs:320,sm:964,md:1080},
        bgcolor: 'background.paper',
      }}
    >
        <Tabs
        value={value1}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          display:'flex',
          alignItems:'center'
        }}
        
      >
 
 <button  className={`list-item ${ (active=="categories" || active==null || name==null)   && 'active'  }`} onClick={()=>{categoryFunc();}}>All Categories</button>     
 {categorylist?.map((item:any,index:any)=>( 
     <>
     <button className={`list-item ${(active == item.name ) && "active"}`} onClick={()=>{handleFunction(item.name);setActive(item.name)}} >{item.name}</button>  
     </>
   ))}

      </Tabs>
      </Box>
</div>
<div className='table-top'>

  <table className='table'>
{(value && name && networkName) ?
threesearchData.length > 0 && 
<>
<tr style={{borderBottom:'1px solid RGB(213 215 217)'}}>
   <th style={{width:'5%',textAlign:'center'}}>#</th>
   <th style={{width:'25%',textAlign:'left'}}>Name</th>
   <th style={{width:'45%',textAlign:'left'}}>Description</th>
   <th style={{width:'15%',textAlign:'left'}}>Category</th>
   <th style={{width:'10%',textAlign:'left'}}>Reviews</th>
   
 </tr>
</>
: 
(value && networkName) ?
networkDatas.length>0    && 
<>
<tr style={{borderBottom:'1px solid RGB(213 215 217)'}}>
   <th style={{width:'5%',textAlign:'center'}}>#</th>
   <th style={{width:'25%',textAlign:'left'}}>Name</th>
   <th style={{width:'45%',textAlign:'left'}}>Description</th>
   <th style={{width:'15%',textAlign:'left'}}>Category</th>
   <th style={{width:'10%',textAlign:'left'}}>Reviews</th>
   
 </tr>
</>
: 
(value && name) ? 
categoryapi.length > 0 && 
<>
<tr style={{borderBottom:'1px solid RGB(213 215 217)'}}>
   <th style={{width:'5%',textAlign:'center'}}>#</th>
   <th style={{width:'25%',textAlign:'left'}}>Name</th>
   <th style={{width:'45%',textAlign:'left'}}>Description</th>
   <th style={{width:'15%',textAlign:'left'}}>Category</th>
   <th style={{width:'10%',textAlign:'left'}}>Reviews</th>
   
 </tr>
</>
:
(value && 
results.length> 0 && 
<>
<tr style={{borderBottom:'1px solid RGB(213 215 217)'}}>
   <th style={{width:'5%',textAlign:'center'}}>#</th>
   <th style={{width:'25%',textAlign:'left'}}>Name</th>
   <th style={{width:'45%',textAlign:'left'}}>Description</th>
   <th style={{width:'15%',textAlign:'left'}}>Category</th>
   <th style={{width:'10%',textAlign:'left'}}>Reviews</th>
   
 </tr>
</>)
}
        {value && 
      <>
       {name ? (
         <>
         {networkName ? 
         (
          <>
          {threesearchData.length == 0 && <img src={datanotfound}></img>}
                { threesearchData && (threesearchData.length > 0) && threesearchData?.map((item:any,index:any)=>(
      <>
      {item?.children?.map((data:any,index:any)=>(
        <tr onClick={()=>handleRowFunction(data.id)} style={{borderBottom:'1px solid RGB(213 215 217)'}} className='table-row' >
          <td className='table-data-index' >{index+1}</td>
        
       <td className='table-data name-profile'  >
        <img src={data?.image} style={{ width: '50px', borderRadius: '12px' }} alt='prod-logo' />
        <div style={{display:'flex',flexDirection:'column',  marginLeft:'15px',gap:'5px'}}>
        <Typography variant="h6"  style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight:'600',
          
          }}>{data?.name}</Typography>
     {data?.networks.length > 2 ?
      <BootstrapTooltip
         
      title=
        {
          <div className='tooltip_container'>
          <p className='cont-para' >Data for this dapp is shown over multiple networks</p>
          {data?.networks?.map((item:any,index:any)=>(
          <div className='tooltip_div'>
          
          <img src={item?.network_logo}  width={20}></img>
          <p >{item?.network_name}</p>
          </div>
        ))}
        </div>}
      
      
    >
     <div className='networks'>
    {data?.networks?.map((item:any,index:any)=>(
     <div>
      <img src={item?.network_logo} width={12}></img>
      </div>
       ))}
    </div>
    </BootstrapTooltip> :  
     (
      
      <>
      {data?.networks.length == 2 && 
      
      <>
      <div className='networks'>
        <div className='network_div'>
        <img src={data.networks[0].network_logo} width={12} height={12}></img>
         <p>{data.networks[0].network_name}</p>
        </div>
         <div className='network_dot'></div>
         <div className='network_div'>
         <img src={data.networks[1].network_logo} width={12} height={12}></img>
         <p>{data.networks[1].network_name}</p>
         </div>
        
      </div>
      </>
      }
      {
        data?.networks.length == 1 &&      
        <div className='networks'>
          <div className='network_div'>
          <img src={data.networks[0].network_logo} width={12} height={12}></img>
           <p>{data.networks[0].network_name}</p>
          </div>
        </div>
      }
      </>
      
        )
     
     }
        
        </div>
      
       </td> 
        
        <td className='table-data'  >
        <Typography  style={{
            margin: '0px',
            color: '#95a5b5',
            fontSize: '13px',
            fontFamily: 'Poppins',
            overflowWrap: 'break-word',
            maxWidth:'400px'
          }}>{data?.description}</Typography>
        </td>
        
      <td className='table-data'  >
      <Typography  style={{
            margin: '0px',
            color: '#95a5b5',
            fontSize: '13px',
            fontFamily: 'Poppins',
          }}>{item?.parent}</Typography>
      </td>
      <td className='table-data' >
      <Rating  value={data?.reviews?.reviews_average} className='star-rating-icon' name="half-rating"/> 
      </td>
       

     
        </tr>
      ))}
      </>
    ))}
          </>
         ) : (
          <>
            {categoryapi.length > 0 ? (
        <>
        {categoryapi?.map((item:any,index:any)=>(
      <>
      {item?.children?.map((data:any,index:any)=>(
        <tr onClick={()=>handleRowFunction(data.id)} style={{borderBottom:'1px solid RGB(213 215 217)'}} className='table-row' >
          <td className='table-data-index' >{index+1}</td>
        
       <td className='table-data name-profile'  >
        <img src={data?.image} style={{ width: '50px', borderRadius: '12px' }} alt='prod-logo' />
        <div style={{display:'flex',flexDirection:'column',  marginLeft:'15px',gap:'5px'}}>
        <Typography variant="h6"  style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight:'600',
          
          }}>{data?.name}</Typography>
     {data?.networks.length > 2 ?
      <BootstrapTooltip
         
      title=
        {
          <div className='tooltip_container'>
          <p className='cont-para' >Data for this dapp is shown over multiple networks</p>
          {data?.networks?.map((item:any,index:any)=>(
          <div className='tooltip_div'>
          
          <img src={item?.network_logo}  width={20}></img>
          <p >{item?.network_name}</p>
          </div>
        ))}
        </div>}
      
      
    >
     <div className='networks'>
    {data?.networks?.map((item:any,index:any)=>(
     <div>
      <img src={item?.network_logo} width={12}></img>
      </div>
       ))}
    </div>
    </BootstrapTooltip> :  
     (
      
      <>
      {data?.networks.length == 2 && 
      
      <>
      <div className='networks'>
        <div className='network_div'>
        <img src={data.networks[0].network_logo} width={12} height={12}></img>
         <p>{data.networks[0].network_name}</p>
        </div>
         <div className='network_dot'></div>
         <div className='network_div'>
         <img src={data.networks[1].network_logo} width={12} height={12}></img>
         <p>{data.networks[1].network_name}</p>
         </div>
        
      </div>
      </>
      }
      {
        data?.networks.length == 1 &&      
        <div className='networks'>
          <div className='network_div'>
          <img src={data.networks[0].network_logo} width={12} height={12}></img>
           <p>{data.networks[0].network_name}</p>
          </div>
        </div>
      }
      </>
      
        )
     
     }
        
        </div>
      
       </td> 
        
        <td className='table-data'  >
        <Typography  style={{
            margin: '0px',
            color: '#95a5b5',
            fontSize: '13px',
            fontFamily: 'Poppins',
            overflowWrap: 'break-word',
            maxWidth:'400px'
          }}>{data?.description}</Typography>
        </td>
        
      <td className='table-data'  >
      <Typography  style={{
            margin: '0px',
            color: '#95a5b5',
            fontSize: '13px',
            fontFamily: 'Poppins',
          }}>{item?.parent}</Typography>
      </td>
      <td className='table-data' >
      <Rating  value={data?.reviews?.reviews_average} className='star-rating-icon' name="half-rating"/> 
      </td>
       

     
        </tr>
      ))}
      </>
    ))}
        </>
      ) 
      : 
      
      (
        <>
        {notfound &&  <img src={datanotfound}></img>}
   
        </>
      )}
          </>
         )
        }
    
            
  
         </>
      ) : (
        <>
        {networkName ?
        <>
        {networkDatas.length > 0 ? (
        <>
          {networkDatas?.map((item:any,ind:any)=>(
          <>
          {item?.children?.map((data:any,index:any)=>(
            <>
            <tr onClick={()=>handleRowFunction(data.id)} key={index} style={{borderBottom:'1px solid RGB(213 215 217)',padding:'10px'}} className='table-row'>
                  <td className='table-data-index'>{ind+1}</td>
                
               <td className='table-data name-profile'  >
                <img src={data?.image} style={{ width: '50px', borderRadius: '12px' }} alt='prod-logo' />
                <div style={{display:'flex',flexDirection:'column',marginLeft:'15px',gap:'5px'}}>
                <Typography variant="h6"  style={{
                    color: '#242424',
                    margin: '0px',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight:'600',
                  }}>{data?.name}</Typography>
                {data?.networks.length > 2 ?
      <BootstrapTooltip
         
      title=
        {
          <div className='tooltip_container'>
          <p className='cont-para'>Data for this dapp is shown over multiple networks</p>
          {data?.networks?.map((item:any,index:any)=>(
          <div className='tooltip_div'>
          
          <img src={item?.network_logo} width={20}></img>
          <p>{item?.network_name}</p>
          </div>
        ))}
        </div>}
      
      
    >
     <div className='networks'>
    {data?.networks?.map((item:any,index:any)=>(
     <div>
      <img src={item?.network_logo} width={12}></img>
      </div>
       ))}
    </div>
    </BootstrapTooltip> :  
     (
      
      <>
      {data?.networks.length == 2 && 
      
      <>
      <div className='networks'>
        <div className='network_div'>
        <img src={data.networks[0].network_logo} width={12} height={12}></img>
         <p>{data.networks[0].network_name}</p>
        </div>
         <div className='network_dot'></div>
         <div className='network_div'>
         <img src={data.networks[1].network_logo} width={12} height={12}></img>
         <p>{data.networks[1].network_name}</p>
         </div>
        
      </div>
      </>
      }
      {
        data?.networks.length == 1 &&      
        <>
        <div className='networks'>
        <div className='network_div'>
          <img src={data.networks[0].network_logo} width={12} height={12}></img>
           <p>{data.networks[0].network_name}</p>
          </div>
        </div>
         
        </>
      }
      </>
      
        )
     
     }
 
                 
                </div>
              
               </td> 
                
                <td className='table-data'  >
                <Typography  style={{
                    margin: '0px',
                    color: '#95a5b5',
                    fontSize: '13px',
                    fontFamily: 'Poppins',
                    overflowWrap: 'break-word',
                    maxWidth:'400px'
                  }}>{data?.description}</Typography>
                </td>
                
              <td className='table-data'  >
              <Typography  style={{
                    margin: '0px',
                    color: '#95a5b5',
                    fontSize: '13px',
                    fontFamily: 'Poppins',
                  }}>{data?.parent}</Typography>
              </td>
              <td className='table-data'>
      <Rating   value={data?.reviews?.reviews_average} className='star-rating-icon' name="half-rating"/> 
      </td>
               
        
             
                </tr>
            </>
          ))}
          </>
        ))}
        </>) : 
        (<>
        (
                   <>
                   {notfound && <img src={datanotfound}></img>}
                   
                   </>
            )
        </>)
        }
      
        </>
        : (
          <>

          {results.length > 0 ?
      
            (
                   <>
                    {results?.map((item:any,index:any)=>( 
             <>
             {item?.childrens?.map((data:any,index:any)=>(
     
                       <tr onClick={()=>handleRowFunction(data.id)} key={index} style={{borderBottom:'1px solid RGB(213 215 217)',padding:'10px'}} className='table-row'>
                       <td className='table-data-index'>{index+1}</td>
                     
                    <td className='table-data name-profile'  >
                     <img src={data?.image} style={{ width: '50px', borderRadius: '12px' }} alt='prod-logo' />
                     <div style={{display:'flex',flexDirection:'column',marginLeft:'15px',gap:'5px'}}>
                     <Typography variant="h6"  style={{
                         color: '#242424',
                         margin: '0px',
                         fontFamily: 'Poppins',
                         fontSize: '14px',
                         fontWeight:'600',
                       }}>{data?.name}</Typography>
                     {data?.networks.length > 2 ?
           <BootstrapTooltip
              
           title=
             {
               <div className='tooltip_container'>
               <p className='cont-para'>Data for this dapp is shown over multiple networks</p>
               {data?.networks?.map((item:any,index:any)=>(
               <div className='tooltip_div'>
               
               <img src={item?.network_logo} width={20}></img>
               <p>{item?.network_name}</p>
               </div>
             ))}
             </div>}
           
           
         >
          <div className='networks'>
         {data?.networks?.map((item:any,index:any)=>(
          <div>
           <img src={item?.network_logo} width={12}></img>
           </div>
            ))}
         </div>
         </BootstrapTooltip> :  
          (
           
           <>
           {data?.networks.length == 2 && 
           
           <>
           <div className='networks'>
             <div className='network_div'>
             <img src={data.networks[0].network_logo} width={12} height={12}></img>
              <p>{data.networks[0].network_name}</p>
             </div>
              <div className='network_dot'></div>
              <div className='network_div'>
              <img src={data.networks[1].network_logo} width={12} height={12}></img>
              <p>{data.networks[1].network_name}</p>
              </div>
             
           </div>
           </>
           }
           {
             data?.networks.length == 1 &&      
             <>
             <div className='networks'>
             <div className='network_div'>
               <img src={data.networks[0].network_logo} width={12} height={12}></img>
                <p>{data.networks[0].network_name}</p>
               </div>
             </div>
              
             </>
           }
           </>
           
             )
          
          }
      
                      
                     </div>
                   
                    </td> 
                     
                     <td className='table-data'  >
                     <Typography  style={{
                         margin: '0px',
                         color: '#95a5b5',
                         fontSize: '13px',
                         fontFamily: 'Poppins',
                         overflowWrap: 'break-word',
                         maxWidth:'400px'
                       }}>{data?.description}</Typography>
                     </td>
                     
                   <td className='table-data'  >
                   <Typography  style={{
                         margin: '0px',
                         color: '#95a5b5',
                         fontSize: '13px',
                         fontFamily: 'Poppins',
                       }}>{data?.parent}</Typography>
                   </td>
                   <td className='table-data'>
           <Rating   value={data?.reviews?.reviews_average} className='star-rating-icon' name="half-rating"/> 
           </td>
                    
             
                  
                     </tr>
             ))}
             </>
          
           ))}
                   </>
            ):(
                   <>
                   {notfound && <img src={datanotfound}></img>}
                   
                   </>
            )
           
           }
           </>
        )
        }    
   
           
     
      
        </>
      )
      }
      </>
      }
      
    
      </table>
      

   
    
      </div>


  
   

    <div> 
      
    
    
      
    
    </div>
    </Box> 
    
  )
}

export default SecondPage;