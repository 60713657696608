
import ReactDOM from 'react-dom/client';

import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/home"
import Layout from "./components/layout/index"
import GridComponent from './components/GridComponent/grid ';
import SecondPage from './components/layout/SecondPage';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import {store,persistor} from './redux/store'
import ViewAllpage from './components/viewComponent/ViewAllpage';





const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,

    children: [
   
      {
        path: "/",
        element: <Home/>,
      },
     {
      path:'/list-apps/:id',
      element:<ViewAllpage/>

     },
      {

      
        path: "grid/:id",
        element: <GridComponent/>,
    },
 
    {
      path:'search/:value',
      element:<SecondPage/>,
    
    },
  
    
    


   
    ],
  },

  

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
<Provider store={store}>
<PersistGate loading={null} persistor={persistor}>
<RouterProvider router={router} />
</PersistGate>
</Provider>



      
);




