
import "./category.css"
import React, { useState, useEffect,useContext } from 'react'
import axios from "axios";
import { Grid, Stack, Typography } from '@mui/material';
import { Chip,Button } from '@mui/material';
import './style.css'
import { useNavigate } from 'react-router-dom';
import ScrollContext from "../../redux/scrollev"
import {Skeleton} from '@mui/material';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#e24c4c',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(226,76,76,0.9)',
    zIndex: 9999,
  },
}));
function Chipcolor() {
  return <Typography className='open-name' style={{
    fontSize: '11px',
    cursor: 'pointer',
    color: '#5c8acb',
    height: '18px',
    fontWeight:'600px',
    fontFamily: 'Poppins'
  }}>OPEN</Typography>
}
function ChildrenGrid1(props:any) {
  const { data } = props
  const navigate = useNavigate();
  const handleClick = (id:any) => {
    navigate('/grid/'+id)
  }

  return (
    <>
      <Grid className='childrengrid1'>
        < Grid  item xs={12} sm={12}>
        {
          data?.feature_image &&
          <img style={{borderRadius:"12px",width:"100%"}}  src={data?.feature_image}  alt='prod-logo'/>
        }
                 
        </Grid>
        <Grid className='onclick-hover' item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px',cursor:'pointer' }}  >
<Grid onClick={()=>handleClick(data.id)} sx={{ display: 'flex' }}>
          <Grid item xs={2} mr={2} style={{ maxWidth: '56px' }}>
          {
          data?.image &&
          <img  src={data?.image} style={{ width: '100%',borderRadius:"12px"}} alt='prod-icon' />
        }
           
          </Grid>
          <Grid item xs={7.5}>
            <Typography style={{
              color: '#242424',
              margin: '0px',
              fontFamily: 'Poppins',
              fontSize: '18px',
            }} className='data-name'>

              {data?.name}</Typography>
            <Typography className='data-desc' style={{
              margin: '0px',
              color: '#56687B',
              fontSize: '12px',
              fontFamily: 'Poppins',
            }}>{data?.description}</Typography>
          </Grid>
          </Grid>
          <Grid className='chip-btn' item xs={2.5} style={{ marginTop: '1rem', textAlign: 'center' }}>
            <a href={ `${data?.download_link}` } >  <Chip label={<Chipcolor />} className='chipbtn'  /> </a>
           
            </Grid>

        </Grid>
      </Grid>

    </>

  );
}
function ChildrenGrid2(props: any) {
  const { data } = props
  const navigate = useNavigate();
  const handleClick = (id:any) => {
    navigate('/grid/'+id)
  }
  return (
    <>
      <Grid className='onclick-hover' item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between',cursor:'pointer' }}  >
      <Grid onClick={()=>handleClick(data.id)} sx={{ display: 'flex' }}>
        <Grid item xs={2} sm={2} md={2} lg={2} mr={2} style={{ maxWidth: "72px" }} >
          <img src={data?.image} style={{ width: '100%', borderRadius: '12px' }} alt='prod-logo' />
        </Grid>
        <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
          <Typography variant="h6" className='data-name' style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '18px',
          }}>{data?.name}</Typography>
          <Typography className='data-desc' style={{
            marginBottom: '3px',
            color: '#56687B',
            fontSize: '12px',
            fontFamily: 'Poppins',
          }}>{data?.description}</Typography>

           {data?.networks.length > 2 ?
      <BootstrapTooltip
         
      title=
        {
          <div className='tooltip_container'>
          <p className='cont-para' >Data for this dapp is shown over multiple networks</p>
          {data?.networks?.map((item:any,index:any)=>(
          <div className='tooltip_div'>
          
          <img src={item?.network_logo}  width={20}></img>
          <p  >{item?.network_name}</p>
          </div>
        ))}
        </div>}
      
      
    >
     <div className='networks'>
    {data?.networks?.map((item:any,index:any)=>(
     <div>
      <img src={item?.network_logo} width={12}></img>
      </div>
       ))}
    </div>
    </BootstrapTooltip> :  
     (
      
      <>
      {data?.networks.length == 2 && 
      
      <>
      <div className='networks'>
        <div className='network_div'>
        <img src={data.networks[0].network_logo} width={12} height={12}></img>
         <p style={{color:'#56687B'}}>{data.networks[0].network_name}</p>
        </div>
         <div className='network_dot'></div>
         <div className='network_div'>
         <img src={data.networks[1].network_logo} width={12} height={12}></img>
         <p style={{color:'#56687B'}}>{data.networks[1].network_name}</p>
         </div>
        
      </div>
      </>
      }
      {
        data?.networks.length == 1 &&      
        <div className='networks'>
          <div className='network_div'>
          <img src={data.networks[0].network_logo} width={12} height={12}></img>
           <p style={{color:'#56687B'}}>{data.networks[0].network_name}</p>
          </div>
        </div>
      }
      </>
      
        )
     
     }
 
        </Grid>
        </Grid>
        <Grid className='chip-btn' item xs={2.5} style={{ marginTop: '1rem', textAlign: 'center' }}>
          <a href={ `${data?.download_link}` }><Chip label={<Chipcolor />} className='chipbtn'  /></a>
          </Grid>

      </Grid>
    </>

  );
}

export default function Category() {

  const [post,setPosts]=useState<any>([])
  const [isLoading,setisLoading]=useState<any>(true)

   useEffect( ()=>{

    setTimeout(()=>{
      setisLoading(false)

    },300)


    async function initial(){
      const { data : datalist  } = await axios.get('https://dappstore.mo.vc/api/dapp-lists')
 
  
     setPosts(datalist)
  

     console.log('datalist',datalist);
    }
    initial()
   },[])
 console.log('post',post);
  const [scroll ] = useContext(ScrollContext) as any;
  console.log('scrolllll',scroll);

 useEffect(()=>{
    
 
    const titleElements: HTMLElement | any  = document?.getElementById(scroll)
    console.log('TitleElements',titleElements);


    const myElement: HTMLElement | any = titleElements?.scrollIntoView({ behavior: 'smooth', inline:"nearest"});
    console.log('MyElement',myElement);
    
    

    

  },[scroll])
  // const nameGrid = useSelector((state:any)=>state.user.name);
  return (
    <>

      <Grid container spacing={2}>
  

        {post?.data?.map((post:any , hn :number) => (
          <>
          {


isLoading?(

  <Grid item md={12} sm={12} lg={12} xl={12} id={`section${post.id}`}>
  <div className='section1-comp5'>
  
   
    {
      (post?.id !== 0) ?
      <>
        < Typography className='home-parent' style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '22px', color: '#242424', whiteSpace: 'nowrap' }}>  <Skeleton  width={100} style={{ marginLeft:'15px'}}/>
</Typography>
      <hr></hr>
      
      </>
     : null
    }
  
  </div>
</Grid>
  
):(
         


            <Grid item md={12} sm={12} lg={12} xl={12} id={`section${post.id}`} className='parent-comp5'>
              
              <div className='section1-comp5'>
             
               
                {
                  (post?.id !== 0) ?
                  <>
                   
                    <Typography className='home-parent' style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '22px', color: '#242424', whiteSpace: 'nowrap' }}>{post?.parent }</Typography>
                   
                  <hr></hr>
                  
                  </>
                 : null
                }
               
              
              </div>
              {
                (post?.id!=0 && post?.id!=1 && post?.id!=2) && 
                <>
                <Link to={`/list-apps/${post.parent_id}`}>View All</Link>
             
                </>

              }
               {
                (post?.id==2) && 
                <>
                 <Link to={`/list-apps/editorchoice`}>View All</Link>
                </>
               }
            </Grid>
)
}
<Stack direction='row' spacing={1} sx={{width:'100%',marginTop:"12px"}}/>




              {post?.children.length > 0 && post.type === 'grid1' && post.children.map((child: any,i:number) => (
              <>

{


isLoading?(
  

  <Grid className='childrengrid1'>
        < Grid  item xs={12} sm={12}>

<Skeleton variant="rectangular" style={{ margin: '20px'}} width={320}  height={140}/>
                 
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px',marginBottom:"10px" }}>

          <Grid item xs={2} mr={2} style={{ maxWidth: '56px' }}>
          {
         
          <Skeleton variant="circular" width={50}  height={50} style={{ width: '100%',  borderRadius:"12px",}} />
        }


           
          </Grid>
          <Grid item xs={7.5}>
            <Typography style={{
              color: '#242424',
              
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 'bold',
            }} className='data-name'>

 <Skeleton variant="rectangular" height={10}/>
</Typography>
            <Typography className='data-desc' style={{
              marginTop: '20px',
              color: '#56687B',
              fontSize: '12px',
              fontFamily: 'Poppins',
              
            }}> <Skeleton variant="rectangular" height={10}/>
            </Typography>
          </Grid>

        </Grid>
      </Grid>



):(


                <Grid item xs={12} sm={12} md={4} >
                  <ChildrenGrid1 data={child} />
                </Grid>

)
}
              </>
            ))} 
            {post?.children.length > 0 && post?.type === 'grid2' && post?.children.map((child: any,i:number) => (

              
              <>

{


isLoading?(
 

<Grid item xs={12} sm={12} md={12} lg={4} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Grid item xs={2} sm={2} md={2} lg={2} mr={2} style={{ maxWidth: "72px" }} >
        <Skeleton variant="circular" width={50}  height={50} style={{  borderRadius:"12px",}} />
        </Grid>
        <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
          <Typography variant="h6" className='data-name' style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 'bold',
          }}><Skeleton variant="rectangular" height={10}/></Typography>
          <Typography className='data-desc' style={{
            marginTop: '15px',
            color: '#56687B',
            fontSize: '12px',
            fontFamily: 'Poppins',
          }}><Skeleton variant="rectangular" height={10}/></Typography>



          


        </Grid>
        

      </Grid>

):(
              
                <Grid item xs={12} sm={12} md={4} >
                  <ChildrenGrid2 data={child} />
                </Grid>
               
                
               )
              }


                
              </>

              




            ))}  


            
          </>
        ))}

      </Grid>
    </>
  )
}


