import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import WalletConnectProvider from '@walletconnect/web3-provider';
import './MetaMask.css';
import './Meta.js';
import { useDispatch } from 'react-redux';
import { update,remove } from '../../redux/userSlice';



const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Web3Modal Demo',
      infuraId: { 3: 'https://ropsten.infura.io/v3/fefnefnesfe' },
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
    },
  },
};

const Metamask = ({ sendDataToParent }) => {
  const [web3Provider, setWeb3Provider] = useState(null);
  const [signature, setSignature] = useState(null);
  const [disconnect,setDisconnect] = useState(null);
  const [metamaskinstalled,setMetamaskinstalled] = useState(false);
  const dispatch = useDispatch()
  const web3Modal = new Web3Modal({
    cacheProvider: true, 
    providerOptions,
  });

  const connectWallet = async () => {
  
    if(window.ethereum){
      setMetamaskinstalled(true);
     }

   

    const web3ModalInstance = await web3Modal.connect();
    const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
    if (web3ModalProvider) {
      setWeb3Provider(web3ModalProvider);
      sendDataToParent(web3ModalProvider.provider.selectedAddress);
      dispatch(update({ name: web3ModalProvider.provider.selectedAddress }));
      const exampleMessage = 'Example `personal_sign` message.';
      const from = web3ModalProvider.provider.selectedAddress;
      const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
      try {
        const sign = await web3ModalProvider.provider.send('personal_sign', [msg, from, 'Example password']);
        setSignature(sign);
      } catch (err) {
        console.error(err);
      }
    }

      
  };

  const disconnectWallet = async () => {
    
    if (web3Provider && web3Provider.provider && web3Provider.provider.disconnect) {
      await web3Provider.provider.disconnect();
    }
    await web3Modal.clearCachedProvider();
    setWeb3Provider(null);
    setSignature(null);
    dispatch(update({name:""}))
  };

  useEffect(() => {
    localStorage.setItem('WebProviderName',web3Provider?.provider?.selectedAddress)
  },[web3Provider])

  useEffect(() => {
 
    async function checkProvider() {
      if (web3Modal.cachedProvider) {
        const web3ModalInstance = await web3Modal.connect();
        const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
        if (web3ModalProvider) {
          setWeb3Provider(web3ModalProvider);
          sendDataToParent(web3ModalProvider.provider.selectedAddress);
       
        }
      }
    }
    checkProvider();
  }, []);
console.log('address',web3Provider?.provider?.isMetaMask);
  return (
    <>
    <div className='metamask-btn1'>
      <button onClick={()=>{connectWallet()}}>
        {web3Provider == null ? (
          <>
            Connect Wallet
          </>
        ) : (
          <div className='provider_address'>
            
            {web3Provider.provider.selectedAddress}
        
          </div>
        )}
      </button> 

      </div>
      {/* <button onClick={handleChange}>ChangeAddress</button> */}
      <div className='metamask-btn2'>
    {web3Provider && (
        <button onClick={disconnectWallet}>
          Logout
        </button>
      )} 

      </div>
  
</>
 
 
  );
};

export default Metamask;