

import  { useState,useEffect } from 'react'
import axios from 'axios';
import { Box } from '@mui/material';
import Category from './category';
import {Skeleton} from "@mui/material";
import "./style.css";



export default function Index() {
  const [image,setimage]=useState<any>([])
  const [isLoading, setIsLoading]=useState(false);
  


    useEffect( ()=>{
    async function initial(){
      const { data : { data } } = await axios.get('https://dappstore.mo.vc/api/background')
      setIsLoading(true);

 setimage(data);

}
initial()
   },[])

  console.log('image',image);



  return (
<>

<Box className='home-page-category' style={{ backgroundColor: 'white', padding: 10, borderRadius: '10px' }}>
{isLoading && 
<>
{
  image[0]?.image_path &&
  <img style={{borderRadius:"12px",width:"100%"}}  src={image[0]?.image_path}  alt='banner'/>
}

</>}
{!isLoading && 
<>

<Skeleton variant="rectangular" className='banner-img' 

/>

</>}



  <Category />
</Box>
</>
  )
}




