
import * as React from 'react';
import {Skeleton} from "@mui/material";
import { styled, createTheme, ThemeProvider} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import "./style.css";
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import axios from 'axios'
import { AiOutlineHome } from "react-icons/ai"
import { Grid } from '@mui/material';
import santalogo from '../../assets/images/santa-logo.png'
import polygon from '../../assets/polygon.svg'
import { ScrollProvider } from "../../redux/scrollev"
import { useNavigate , Outlet, createSearchParams} from 'react-router-dom';
import { useState,useEffect } from 'react';
import home from '../../assets/Path 705.svg';
import searchicon from '../../assets/images/loupe.png'
import Metamask from '../home/Metamask';


const drawerWidth: number = 225;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);






export default function DashboardContent(props: any) {

  let hn = localStorage.getItem("thememode") || 'light'
  const [mode, setMode] = useState<any>(hn);

useEffect(() => {
    
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? "dark" : "light";

           if (colorScheme === 'dark')
              document.body.classList.add('dark-mode')
            else
              document.body.classList.remove('dark-mode')

        localStorage.setItem("thememode",colorScheme)
        setMode(colorScheme);
      });
  }, []);


useEffect(()=>{
    
    if (mode === 'dark')
        document.body.classList.add('dark-mode')
      else
        document.body.classList.remove('dark-mode')
  })



  const[open] =useState(true);
  const [ menu , setMenu ] = useState<any>({
    data : [] as any
  })


  const [isLoading, setIsLoading]=useState(false);

 useEffect(()=>{
    async function initial(){
     const { data : { data }  } =  await axios.get("https://dappstore.mo.vc/api/categories")
     setMenu((prev:any)=>({...prev,data}))
     setIsLoading(true);
    }
    initial()
  },[])
  

  const mdTheme = createTheme({
    palette: {
      mode: mode
    }
  });

  const [statemenu, setStatemenu] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer =
    (anchor: any, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
           ((event as React.KeyboardEvent).key  === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setStatemenu({ ...statemenu, [anchor]: open });
      };

  const [scrollev,setScrollev] = React.useState("section1");
  const navigate = useNavigate();
  const functionhandle =(res:any)=>{
    navigate("/");
    setTimeout(() => {
      setScrollev(`section${res.id}`)
    },500);

  }
  const functionhand = () =>{
    navigate("/");
    window.scrollTo(0,0);
  }
// Search Functionality 

const [searchText, setSearchText] = useState('');

  const handleChange = (event:any) => {
     setSearchText(event.target.value);
  
  };
  const handleKeyChange = (event:any)=>{
    if (event.key === "Enter") {
      setSearchText(event.target.value);
      {searchText && 
        navigate(`search/${searchText}`); 
      }
      
    }
  }
  const searchPage = () =>{
    {searchText && 
       navigate(`search/${searchText}`);
          
    }
  }
  const sendDataToParent = (dataProvider:any) => { // the callback. Use a better name
    console.log("parentwebprovider,"+dataProvider);
  };


  const list = (anchor: any) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 225 }}
     className='side-bar-mobile'
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >

<List component="nav" className='main-list-items' style={{borderRadius:"10px"}} >
     
<div className='SearchFunc'>
               <input 
               type="text"      
               value={searchText} 
               onChange={(event)=>{handleChange(event)}} 
               onKeyDown={(event)=>{handleKeyChange(event)}}
               placeholder="Search" 
               className='inputField'/>
                <img className='searchIcon' src={searchicon} width={16} height={16} onClick={searchPage}></img>
              
               </div>  
 
               <Metamask sendDataToParent={sendDataToParent} />
     
     {isLoading && 
     <>
     <ListItemButton className='button side-bar-btn' onClick={functionhand}>
       <ListItemIcon className='side-bar-icon'>
         {mode==="dark" ? <img src={home} style={{width:"20px",height:"20px"}}></img> :  <AiOutlineHome className='home' style={{width:"20px",height:"20px"}}/> }
     
           </ListItemIcon>
     
           <ListItemText primary={<Typography  className='side-bar-name ' style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27 ,marginBottom:-3}}>Store Home</Typography>} />
           <div className='right-arrow-icon' >
             <svg xmlns="http://www.w3.org/2000/svg" height="18" width="24"><path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z" /></svg>
           </div>
         </ListItemButton>
     </>}
     {
       !isLoading && 
       <>
       <ListItemButton className='button side-bar-btn'  >
       <ListItemIcon className='side-bar-icon'>
            <Skeleton className='skeleton-icon' />
           </ListItemIcon>
     
           <ListItemText primary={<Typography  className='side-bar-name ' style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27 ,marginBottom:-3}}>
           <Skeleton width={140}/>
           </Typography>} />
          
         </ListItemButton>
       </>
     }
     
     {isLoading && 
     <>
     {
       menu?.data?.length > 0 && menu?.data?.map((res:any , index:number)=>(
                 
             <ListItemButton className='button side-bar-btn' 
             onClick={()=>{functionhandle(res)}} >
               <ListItemIcon>
     {mode==="dark" ?
     <img src={res?.lightmode_logo} className='lightmodelogo'  style={{width:"18px",height:"18px"}} alt='dark-mode-logos' 
                  
              /> : <img src={res?.darkmode_logo} className='darkmodelogo'  style={{width:"18px",height:"18px"}} alt='light-mode-logos'
                  
              />
     }
               
                   
               </ListItemIcon>
               <ListItemText primary={<Typography  className='side-bar-name'
                style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27,marginBottom: -3 }}>{res?.name}</Typography>} />
               <div className='right-arrow-icon'  >
                 <svg xmlns="http://www.w3.org/2000/svg" height="18" width="24"><path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z" /></svg>
               </div>
             </ListItemButton>
       ))
      }
     
     
     </>
     }
     {
       !isLoading && 
       <>
     {
       [1,2,3,4,5,6,7].map((index)=>(
      <ListItemButton className='button side-bar-btn'>
       <ListItemIcon>
       <Skeleton  className='skeleton-icon' />
       </ListItemIcon>
     
     <ListItemText primary={<Typography  className='side-bar-name'
                style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27,marginBottom: -3 }}>
                 <Skeleton width={140}/>
                </Typography>} />
      </ListItemButton>
       ))
     }
       </>
     }
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
                   </List>
                   <Box
  sx={{
    mr: 2,
    mt:3,
    

  }}
  className='logo-box'
>
  {isLoading && 
  <>
 <ListItem style={{paddingLeft:"25px"}} className='logo-details'
>
    <ListItemIcon>
      <img src={santalogo} alt='santa-logo-icons' className='santa-logo-icons'></img>
    </ListItemIcon>
    <ListItemText primary={<Typography className='santa-name' style={{ fontSize: 16, fontFamily: 'Poppins', color: 'rgba(22,24,26,1)', opacity: 1, marginLeft: -27,marginBottom:-3}}>Santa dApps Store</Typography>} />
  </ListItem>
  </>}

  {!isLoading && 
  <>
    <ListItem style={{paddingLeft:"25px"}} 
>
    <ListItemIcon>
   <Skeleton className='skeleton-icon' />
    </ListItemIcon>
    <ListItemText primary={<Typography className='santa-name' style={{ fontSize: 14, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27, fontWeight: 'bold' ,marginBottom:-3}}>

      <Skeleton width={140}/>
    </Typography>} />
  </ListItem>
  </>}

</Box>
                            

    </Box>
  );

  return (
    <div>

<ScrollProvider value={[scrollev,setScrollev]}>

      <ThemeProvider theme={mdTheme}>
        <Box className='background-home'>
          <Box
            sx={{
              marginRight:"10px",
              borderRadius:"10px",
             
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block"
              },

            }}
          >
            <Drawer variant="permanent" open={open} className='drawer' style={{  position: "sticky",top: 0,height: "100vh" }} >

              <List component="nav" className='main-list-items' style={{borderRadius:"10px",position:'relative'}} >

                {/* Search Functionality  */}
               <div className='SearchFunc'>
               <input 
               type="text"      
               value={searchText} 
               onChange={(event)=>handleChange(event)} 
               onKeyDown={(event)=>handleKeyChange(event)}
               placeholder="Search" 
               className='inputField'/>
                <img className='searchIcon' src={searchicon} width={16} height={16} onClick={searchPage}></img>
              
               </div>
                
     
             
{/* <form onSubmit={inputFunction}> 
<div className='side-search-bar'>
<input placeholder='Search'  onChange={(e)=>setInput(e.target.value)} className='search-bar-dark' style={{ border: 'none', width: '200px', height: '38px', backgroundColor: '#F0F3F7', paddingLeft: '40px', borderRadius: '10px', marginLeft: '10px', marginTop: '21px', marginBottom: '10px' }} />

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className='search-icon'>
<g id="Search_Icon" data-name="Search Icon" transform="translate(-97 -62)">
<rect id="Rectangle_29475" data-name="Rectangle 29475" width="16" height="16" transform="translate(97 62)" fill="#fff" opacity="0"/>
<path id="Path_708" data-name="Path 708" d="M953.25,222.565a5.315,5.315,0,1,1,9.382,3.422l3.545,3.545a.455.455,0,0,1-.644.644l-3.545-3.544a5.315,5.315,0,0,1-8.737-4.067Zm5.315-4.4a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,958.565,218.161Z" transform="translate(-854.648 -153.942)" fill="#56687b" fill-rule="evenodd"/>
</g>
</svg>
</div>  
</form> */}



{isLoading && 
<>
<ListItemButton className='button side-bar-btn' onClick={functionhand} >
  <ListItemIcon className='side-bar-icon'>
    {mode==="dark" ? <img src={home} style={{width:"20px",height:"20px"}}></img> :  <AiOutlineHome className='home' style={{width:"20px",height:"20px"}}/> }

      </ListItemIcon>

      <ListItemText primary={<Typography  className='side-bar-name ' style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27 ,marginBottom:-3}}>Store Home</Typography>} />
      <div className='right-arrow-icon' >
        <svg xmlns="http://www.w3.org/2000/svg" height="18" width="24"><path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z" /></svg>
      </div>
    </ListItemButton>
</>}
{
  !isLoading && 
  <>
  <ListItemButton className='button side-bar-btn'  >
  <ListItemIcon className='side-bar-icon'>
       <Skeleton className='skeleton-icon' />
      </ListItemIcon>

      <ListItemText primary={<Typography  className='side-bar-name ' style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27 ,marginBottom:-3}}>
      <Skeleton width={140}/>
      </Typography>} />
     
    </ListItemButton>
  </>
}

{isLoading && 
<>
{
  menu?.data?.length > 0 && menu?.data?.map((res:any , index:number)=>(
            
        <ListItemButton className='button side-bar-btn' 
        onClick={()=>{functionhandle(res)}} >
          <ListItemIcon>
{mode==="dark" ?
<img src={res?.lightmode_logo} className='lightmodelogo'  style={{width:"18px",height:"18px"}} alt='dark-mode-logos' 
             
         /> : <img src={res?.darkmode_logo} className='darkmodelogo'  style={{width:"18px",height:"18px"}} alt='light-mode-logos'
             
         />
}
          
              
          </ListItemIcon>
          <ListItemText primary={<Typography  className='side-bar-name'
           style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27,marginBottom: -3,
       }}>{res?.name}</Typography>} />
          <div className='right-arrow-icon'  >
            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="24"><path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z" /></svg>
          </div>
        </ListItemButton>
  ))
 }


</>
}

{
  !isLoading && 
  <>
{
  [1,2,3,4,5,6,7].map((index)=>(
 <ListItemButton className='button side-bar-btn'>
  <ListItemIcon>
  <Skeleton  className='skeleton-icon' />
  </ListItemIcon>

<ListItemText primary={<Typography  className='side-bar-name'
           style={{ fontSize: 16, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27,marginBottom: -3 }}>
            <Skeleton width={140}/>
           </Typography>} />
 </ListItemButton>
  ))
}
  </>
}



<Metamask sendDataToParent={sendDataToParent} />


              </List>
              {/* <Copyright sx={{ pt: 4 }} /> */}
             <Box
  sx={{
    mr: 2,
    mt:3,
    

  }}
  className='logo-box'
>
  {isLoading && 
  <>
 <ListItem style={{paddingLeft:"25px"}} className='logo-details'
>
    <ListItemIcon>
      <img src={santalogo} alt='santa-logo-icons' className='santa-logo-icons'></img>
    </ListItemIcon>
    <ListItemText primary={<Typography className='santa-name' style={{ fontSize: 16, fontFamily: 'Poppins', color: 'rgba(22,24,26,1)', opacity: 1, marginLeft: -27,marginBottom:-3}}>Santa dApps Store</Typography>} />
  </ListItem>
  </>}

  {!isLoading && 
  <>
    <ListItem style={{paddingLeft:"25px"}} 
>
    <ListItemIcon>
   <Skeleton className='skeleton-icon' />
    </ListItemIcon>
    <ListItemText primary={<Typography className='santa-name' style={{ fontSize: 14, fontFamily: 'Poppins', color: '#56687B', opacity: 1, marginLeft: -27, fontWeight: 'bold' ,marginBottom:-3}}>

      <Skeleton width={140}/>
    </Typography>} />
  </ListItem>
  </>}

</Box>
            </Drawer>
          </Box>




          <SwipeableDrawer
            anchor={'left'}
            open={statemenu['left']}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
          </SwipeableDrawer>


          <Box
            component="main"
            sx={{
              backgroundColor:'white',
              
              borderRadius:'10px',
              
            }}
            className='main-class'
            // sx={{
            //   backgroundColor: (theme) =>
            //     theme.palette.mode === 'light'
            //       ? theme.palette.grey[100]
            //       : theme.palette.grey[900],
            //   flexGrow: 1,
            //   
            // }}
          >
            <p className='app-bar'>

              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                className='iconbutton-hamburger'
                sx={{
                  mr: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                    md:"none",
                    lg:"none",
                  },
              
                 
                }}
              >
                <Grid item xs={12} sm={12} className='applogo' >
                  <Grid className="hamburger" item xs={3} md={3} >
                    <MenuIcon style={{ color: 'black', fontSize: 30 }} onClick={toggleDrawer('left', true)} />
                  </Grid>
                  <Grid className='santa-logo' item xs={9} sm={9} >
                    <ListItem  >
                      <ListItemIcon>
                        <img src={santalogo} alt='santa-logo' style={{width:'22px'}}></img>
                      </ListItemIcon>
                      <ListItemText  primary={<Typography className='santa-logo-name' style={{ fontSize: 13, fontFamily: 'Poppins', color: 'rgba(22,24,26,1)', opacity: 1, marginLeft: -27, fontWeight: 'bold',marginBottom:-3 }}>Santa dApps Store</Typography>} />
                    </ListItem>
                  </Grid>
                </Grid>
              </IconButton>

            </p>



            <Box className='children-category'  sx={{ margin: '0px', padding: '0px', borderRadius: '10px' }}>
         <Outlet /> 
            </Box>


            
    

          </Box>

         
        </Box>
             

   
      </ThemeProvider>
     
      </ScrollProvider>
      <footer>
      <Grid  className='polygon-img'sx={{backgroundColor:"#eeeeee",width:"100%",display:"flex",justifyContent:"center"}}>
     <img src={polygon} style={{width:"200px",paddingTop:"22px",paddingBottom:"20px"}} alt='polygon'></img>
    
   </Grid>
</footer> 
    </div>
  
  );
}



