
const lightbox = document.querySelector('.web3modal-modal-lightbox');

const container = document.querySelector('.web3modal-modal-container');

const modalCard = document.querySelector('.web3modal-modal-card');

const hitbox = document.querySelector('web3modal-modal-hitbox');
const cards = document.querySelector('.web3modal-provider-wrapper');
console.log('cardssss',modalCard);
  const bodyCard= document.querySelector('.body-class');

const closeButton = document.createElement('button');
closeButton.innerHTML = 'Close';
let data=false
const para = document.createElement("p");

const node = document.createTextNode("Connect");
para.appendChild(node);
// modalCard.insertBefore(para, cards);


// if(modalCard.classList.contains('cMFuDJ')){
//   let data=true;
// }
console.log(data)
closeButton.addEventListener('click', () => {
  lightbox.classList.remove("uYscM");
  container.classList.remove("fmSSut");
  modalCard.classList.remove("cMFuDJ"); 
  lightbox.classList.add("VgIbA");
  container.classList.add("hURhkl");
  modalCard.classList.add("XXpyx"); 
 bodyCard.style.overflow= 'scroll';
});

  // modalCard.appendChild(closeButton);



// console.log('checkexist',modalCard.classList.contains('dKfWpB'))

closeButton.style.position = 'absolute';
closeButton.style.top = '10px';
closeButton.style.right = '10px';
closeButton.style.padding = '5px';
closeButton.style.border = 'none';
closeButton.style.background = '#ccc';
closeButton.style.borderRadius = '5px';
closeButton.style.cursor = 'pointer';
//     const buttons = document.querySelectorAll(".web3modal-provider-description");
    
    // buttons.forEach((userItem) => {
    //     var h1 = document.createElement("h3");
    //     h1.innerHTML = ">";
    //     userItem.innerHTML="";
    //     userItem.appendChild(h1);
    //   });

    

    


  