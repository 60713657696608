import React,{useEffect,useState,useRef} from 'react'
import { useParams } from 'react-router'
import axios from 'axios';
import { Typography,Rating,Box } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import search from '../../assets/images/loupe.png';
import './ViewAllpage.css'
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import  HorizontalScrollMenu from 'react-horizontal-scrolling-menu';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClipLoader from "react-spinners/ClipLoader";



let resultindex=1;
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? '#f0f3f7' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));



const ViewAllpage = () => {
  const {id} = useParams();
  const [page, setPage] = useState(1);
  const [results,setResults] = useState<any>([]);
  const [categorylist,setCategorylist] = useState<any>([]);
  const [active,setActive]=useState<any>(null);
  const navigate = useNavigate();
  const [networklist,setNetworklist] = useState<any>([])
  const [networkselect,setNetworkselect] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const sectionRef = useRef<any>(null);
  console.log(networkselect);
  const handleRowFunction = (id:any) => {
    navigate('/grid/'+id)
  }
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#e24c4c',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(226,76,76,0.91)',
      zIndex: 9999,
    },
  }));

  function handleScroll() {
 
    const windowHeight =
    "innerHeight" in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = windowHeight + window.pageYOffset;
  if (windowBottom >= docHeight) {
    setPage((prevPage) => prevPage + 1);
   
  }
};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    window.scrollTo(0,0)
    setPage(1);
    setIsLoading(true)
    async function initial(){
 
        const { data } = await axios.get(`https://dappstore.mo.vc/api/view-dapp?content=${id}&&page=${page}`);
        setResults(data);
        setIsLoading(false)
     }

    initial()
  }, [id])

  useEffect(() => {
    setIsLoading(true)
    async function initial(){
  
      const { data } = await axios.get(`https://dappstore.mo.vc/api/view-dapp?content=${id}&&page=${page}`);
      if(page > 1) {
        setResults((prevResults:any) => [...prevResults, ...data]);
        setIsLoading(false)
      } 
     
    }
   
    initial()
  }, [page])
  console.log('page',page);

  useEffect( ()=>{
    async function initial(){
      const { data   } = await axios.get('https://dappstore.mo.vc/api/category-list')
      setCategorylist(data);
     
     }
    initial()
   },[])
   useEffect( ()=>{
    async function initial(){
      const { data  } = await axios.get('https://dappstore.mo.vc/api/network-list')
      setNetworklist(data);
     }
    initial()
   },[])
  console.log('Resultss',results,id);
  const handleFunction = (id:any) =>{
    navigate(`/list-apps/${id}`)

  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const open = Boolean(anchorEl);
const handleClickprotocol = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};

const [value1,setValue1]= useState<any>(0);
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue1(newValue);
};

console.log('ResultsData',results);


  return (

<div style={{padding:'10px'}} ref={sectionRef}>
        <h1 className="back-icon" onClick={()=>navigate('/')}><ChevronLeftIcon/>Back</h1>
       
      
     <div className='protocol'>

<Button
id="demo-customized-button"
aria-controls={open ? 'demo-customized-menu' : undefined}
aria-haspopup="true"
aria-expanded={open ? 'true' : undefined}
variant="contained"
disableElevation
onClick={handleClickprotocol}
endIcon={<KeyboardArrowDownIcon />}
className="protocol-btn"
>
{networkselect ? networkselect : "All Protocols"}
</Button>

  <StyledMenu
    id="demo-customized-menu"
    MenuListProps={{
      'aria-labelledby': 'demo-customized-button',
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    className='styled_menu'
  >
   {networklist?.map((item:any,index:any)=>(
<MenuItem onClick={()=>{setNetworkselect(item.network);handleClose()}} disableRipple key={index} className='network_dropdown'>
<img src={item?.logo} width={18}></img>
<p className='network_name'>{item?.network}</p>

</MenuItem>
))}
  
  </StyledMenu>
</div>
      <div className='category_list_view'>
      
  <Box
      sx={{
        flexGrow: 1,
      }}
     
    >
        <Tabs
        value={value1}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
       
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          display:'flex',
          alignItems:'center'
        }}
      >
 
          
 {categorylist?.map((item:any,index:any)=>( 
     <>
     <button className={`list-item ${(active == item.name || item.id == id) && "active"}`} onClick={()=>{handleFunction(item.id);setActive(item.name)}} >{item.name}</button>  
     </>
   ))}

      </Tabs>
      </Box>
 </div>

 
    <div className='table-top-viewall'>
    <div className='results-page'  >
    <table className='table'>
    
         <tr style={{borderBottom:'1px solid RGB(213 215 217)'}}>
            <th style={{width:'5%',textAlign:'center'}}>#</th>
            <th style={{width:'30%',textAlign:'left'}}>Name</th>
            <th style={{width:'45%',textAlign:'left'}}>Description</th>
            <th style={{width:'20%',textAlign:'left'}}>Reviews</th>
            
          </tr>
       
          <>
                    {results?.map((item:any,index:any)=>(
      <>
      
      {item?.children?.map((data:any,index:any)=>(
       
        <tr onClick={()=>handleRowFunction(data.id)} style={{borderBottom:'1px solid RGB(213 215 217)'}} className='table-row' >
          <td className='table-data-index' >{index+1}</td>
        
       <td className='table-data name-profile'  >
        <img src={data?.image} style={{ width: '50px', borderRadius: '12px' }} alt='prod-logo' />
        <div style={{display:'flex',flexDirection:'column',  marginLeft:'15px',gap:'5px'}}>
        <Typography variant="h6"  style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight:'600',
          
          }}>{data?.name}</Typography>
                {data?.networks.length > 2 ?
      <BootstrapTooltip
         
      title=
        {
          <div className='tooltip_container'>
          <p className='cont-para'>Data for this dapp is shown over multiple networks</p>
          {data?.networks?.map((item:any,index:any)=>(
          <div className='tooltip_div'>
          
          <img src={item?.network_logo} width={20}></img>
          <p>{item?.network_name}</p>
          </div>
        ))}
        </div>}
      
      
    >
     <div className='networks'>
    {data?.networks?.map((item:any,index:any)=>(
     <div>
      <img src={item?.network_logo} width={12}></img>
      </div>
       ))}
    </div>
    </BootstrapTooltip> :  
     (
      
      <>
      {data?.networks.length == 2 && 
      
      <>
      <div className='networks'>
        <div className='network_div'>
        <img src={data.networks[0].network_logo} width={12} height={12}></img>
         <p>{data.networks[0].network_name}</p>
        </div>
         <div className='network_dot'></div>
         <div className='network_div'>
         <img src={data.networks[1].network_logo} width={12} height={12}></img>
         <p>{data.networks[1].network_name}</p>
         </div>
        
      </div>
      </>
      }
      {
        data?.networks.length == 1 &&      
        <>
        <div className='networks'>
        <div className='network_div'>
          <img src={data.networks[0].network_logo} width={12} height={12}></img>
           <p>{data.networks[0].network_name}</p>
          </div>
        </div>
         
        </>
      }
      </>
      
        )
     
     }
 
       
   
        
                   
                 
                 
        </div>
      
       </td> 
        
        <td className='table-data'  >
        <Typography  style={{
            margin: '0px',
            color: '#95a5b5',
            fontSize: '13px',
            fontFamily: 'Poppins',
            overflowWrap: 'break-word',
            maxWidth:'400px'
          }}>{data?.description}</Typography>
        </td>
      <td className='table-data' >
      <Rating  value={data?.reviews?.reviews_average} className='star-rating-icon' name="half-rating"/> 
      </td>
       

     
        </tr>
      ))}
      </>
    ))}
 
          </>
        
          <ClipLoader
        color="black"
        loading={isLoading}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
        
 
      
        </table>
       
        
        </div>
      
        </div>
        </div>
   
    )
    

}

export default ViewAllpage


